import { grey } from '@mui/material/colors';
import {useMuseumSelectorState} from "../museum-selector/museumSelectorContext";
import {useEffect, useState} from "react";

export const defaultThemeOptions = {
    palette: {
        primary: {
            main: '#673ab7',
            light: '#9a67ea',
            dark: '#320b86',
            test: '#FFF59D',
        },
        secondary: {
            main: '#00BCD4',
        },
        background: {
            default: grey[200],
            dark: grey[300],
        },
        charts: {
            bar: {
                main: '#43a047',
                light: '#76d275',
                dark: '#00701a',
            },
        },
    },
    props: {
        MuiTextField: {
            variant: 'filled',
            color: 'secondary',
        },
        MuiFormLabel: {
            color: 'secondary',
        },
        MuiSelect: {
            variant: 'filled',
            color: 'secondary',
        },
    },
    drawerWidth: 240,
};

export const useThemeOptions = () => {
    const { selectedMuseum } = useMuseumSelectorState();
    const [options, setOptions] = useState(defaultThemeOptions)

    useEffect(
        () => {
            if (selectedMuseum?.primary_color) {
                setOptions({
                    ...defaultThemeOptions,
                    palette: {
                        ...defaultThemeOptions.palette,
                        primary: {
                            ...defaultThemeOptions.palette.primary,
                            main: selectedMuseum.primary_color,
                            dark: selectedMuseum.dark_color,
                            light: selectedMuseum.light_color,
                        },
                    },
                })
            } else {
                setOptions(defaultThemeOptions)
            }
        }, [selectedMuseum]
    )
    return options
}