import React, {useCallback, useEffect} from 'react';
import {SET_USER_DATA, useAuthsDispatch, useAuthsState} from "./authsContext";
import {kitFetch} from "@ekultur/fetch";
import {getI18n} from "react-i18next";

export const WithUserdata = ({children}) => {
    const authsDispatch = useAuthsDispatch();
    const {
        userData,
        userIsAuthenticated,
        loginCount
    } = useAuthsState()
    const authsDispatchCallback = useCallback(action => authsDispatch(action), [authsDispatch])

    useEffect(() => {
        if(userIsAuthenticated) {
            kitFetch(`/authz/authorize/userData`)
                .then(json => {
                    authsDispatchCallback({ type: SET_USER_DATA, payload: json });
                })
                .catch(error => {
                    console.error(error)
                });
        }
    }, [authsDispatchCallback, userIsAuthenticated, loginCount])

    useEffect(
        () => {
            if(userData.locale) {
                getI18n().changeLanguage(userData.locale)
            }
        }, [userData.locale]
    )

    if(Object.keys(userData).length) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}