import {makeStyles} from '@mui/styles';
import React from 'react';
import {Callback} from './auths/Callback';
import QrScannerView from './views/QrScannerView';
import {TicketValidationTranslationProvider} from './views/TicketValidationTranslationContext';
import {WithUserdata} from "./auths/WithUserdata";
import {WithAuthentication} from "./auths/withAuthentication";
import {QrScannerViewRoute} from "./views/QrScannerViewRoute";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
}));
export const Routes = () => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <WithAuthentication>
                <Callback/>
                <WithUserdata>
                    <TicketValidationTranslationProvider>
                        <QrScannerViewRoute>
                            <QrScannerView/>
                        </QrScannerViewRoute>
                    </TicketValidationTranslationProvider>
                </WithUserdata>
            </WithAuthentication>
        </div>
    );
};
