import React from 'react';
import {Header} from '@ekultur/header-microfrontend';
import {useAuthsDispatch, useAuthsState, USER_LOGGED_OUT} from "../auths/authsContext";
import {login, logout} from "@ekultur/authentication";
import {useLocation} from "react-router-dom";
import {MENU_CLOSED, MENU_OPENED, RENDERED, useAppDispatch, useAppState} from "../app/appContext";
import {Box, Toolbar} from "@mui/material";
import {ProgressIndicator} from "../ProgressIndicator";
import {defaultThemeOptions} from "../app/useThemeOptions";

export const AppHeader = () => {
    const {userIsAuthenticated, userData} = useAuthsState()
    const {menuOpen} = useAppState()
    const dispatchAuths = useAuthsDispatch()
    const appDispatch = useAppDispatch()
    const location = useLocation();

    const loginHandler = () => {
        const prevPath = location.state && location.state.previousPath ? location.state.previousPath : "/"
        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST
        })
    }

    const logoutHandler = () => {
        logout({
            onLogout: () => dispatchAuths({type: USER_LOGGED_OUT})
        })
    }

    const readyLoggedIn = Object.keys(userData).length

    const progressIfNotLoggedIn = () => {
        if(userIsAuthenticated && !Boolean(readyLoggedIn)) {
            return (
                <Box sx={{width: "100%"}}>
                    <Toolbar />
                    <ProgressIndicator />
                </Box>
            )
        } else {
            return null
        }
    }

    const menuHandler = () => {
        appDispatch({type: menuOpen ? MENU_CLOSED : MENU_OPENED})
    }

    console.debug({userData})

    return (
        <Box>
            <Header
                alwaysShowHamburger={true}
                appId={window._env_.REACT_APP_ID}
                loggedIn={Boolean(readyLoggedIn) && userIsAuthenticated}
                mobileOpenHandler={menuHandler}
                logoutHandler={() => logoutHandler()}
                loginHandler={loginHandler}
                mobileOpen={menuOpen}
                onRender={() => appDispatch({type: RENDERED})}
                version={window._env_.REACT_APP_VERSION}
                theme={defaultThemeOptions}
                showMuseums={false}
                actions={[]}
                user={userData}
            />
            {progressIfNotLoggedIn()}
        </Box>
    )
}