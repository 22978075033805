import {Container, styled, Toolbar} from "@mui/material";
import {useAppState} from "./appContext";

const Main = styled("main")(({theme, menuOpen}) => ({
    flexGrow: 1,
    padding: 0,
    marginLeft: "0px",
    height: "100vh",
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1),
        marginLeft: menuOpen ? theme.drawerWidth : "0px"
    },
}))

export const Layout = ({children}) => {
    const {menuOpen} = useAppState();
    return (
        <Main
            menuOpen={menuOpen}
        >
            <Container>
                <Toolbar />
                {children}
            </Container>
        </Main>
    )
}