import React from 'react';
import { Typography } from '@mui/material';
import { useTicketValidationTranslation } from '../views/TicketValidationTranslationContext';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import 'moment/locale/nb';
moment.locale('nb');

const ScanResultContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
    width: '50%',
  },
}));

export default function ScanResult({ validationAttempt }) {
  const { status, ticketId, message } = validationAttempt;
  const t = useTicketValidationTranslation();

  return (
    <ScanResultContainer>
      {validationAttempt && (
        <>
          <Typography variant={'h6'}>
            {t('scanResultHeading')} {ticketId}
          </Typography>
          {status === 'validated' ? (
            <CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green', margin: '10px', fontSize: '4rem' }} />
          ) : (
            <CancelIcon fontSize={'large'} style={{ color: 'red', margin: '10px', fontSize: '4rem' }} />
          )}
          <Typography>
            {t('validationAttemptMessage')} {message}
          </Typography>
        </>
      )}
    </ScanResultContainer>
  );
}
