import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

export const ProgressIndicator = () => (
  <div>
    <Fade in={true} style={{ transitionDelay: '400ms' }} unmountOnExit={true}>
      <LinearProgress />
    </Fade>
  </div>
);
