import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTicketValidationTranslation } from '../../views/TicketValidationTranslationContext';
import moment from 'moment';
import 'moment/locale/nb';
moment.locale('nb');

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
  paper: {
    textAlign: 'left',
    padding: '10px',
    margin: '10px',
    alignItems: 'left',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
}));

const Item = ({ attemptAt, message, museumName, status, type }) => {
  const classes = useStyles();
  const datetimeFormat = 'DD.MM.YYYY HH:mm';
  const t = useTicketValidationTranslation();

  return (
    <Paper className={classes.paper} elevation={3}>
      <Typography variant="button">
        {moment(attemptAt).locale('no').format(datetimeFormat)}
      </Typography>
      <div>
        {status === 'validated' && (
          <CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green', margin: '10px', fontSize: '2rem' }} />
        )}
        {status !== 'validated' && (
          <CancelIcon fontSize={'large'} style={{ color: 'red', margin: '10px', fontSize: '2rem' }} />
        )}
      </div>
      <div className={classes.info}>
        <Typography variant="button">{t('museum')}: </Typography>
        <Typography> {museumName}</Typography>
      </div>
      <div className={classes.info}>
        <Typography variant="button">{t('status')}: </Typography>
        <Typography> {status}</Typography>
      </div>
      <div className={classes.info}>
        <Typography variant="button">{t('message')}: </Typography>
        <Typography> {message}</Typography>
      </div>
      <div className={classes.info}>
        <Typography variant="button">{t('validationType')}: </Typography>
        <Typography> {type}</Typography>
      </div>
    </Paper>
  );
};

export default Item;
