import {Box, createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from 'react';
import {defaultThemeOptions, useThemeOptions} from "./useThemeOptions";

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '10px',
    },
    '*::-webkit-scrollbar-track-piece': {
      backgroundColor: '#FFF',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#CBCBCB',
      outline: '2px solid #FFF',
      outlineOffset: '-2px',
      border: '.1px solid #B7B7B7',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#909090',
    },
  },
}));

export const Theme = ({ children }) => {
  const classes = useStyles();
  const [appTheme, setAppTheme] = useState(createTheme(defaultThemeOptions));
  const themeOptions = useThemeOptions()

  useEffect(
      () => setAppTheme(createTheme(themeOptions)),
      [themeOptions]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <Box className={classes.root}>{children}</Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
