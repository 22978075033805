import React from 'react';

export const USER_LOGGED_IN = 'authsContext/loggedIn';
export const USER_LOGGED_OUT = 'authsContext/loggedOut';
export const SET_USER_DATA = 'authsContext/setUserData';
export const SET_USER_ACCESS_MUSEUM_IDS = 'authsContext/setUserAccessMuseumIds';

const AuthsStateContext = React.createContext(undefined);
const AuthsDispatchContext = React.createContext(undefined);

const initialState = {
  userIsAuthenticated: false,
  userData: {},
  userName: '',
  userAccessMuseumIds: [],
};

const authsReducer = (state, action) => {
  console.debug({action})
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        userIsAuthenticated: true,
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        userIsAuthenticated: false,
      };
    case SET_USER_DATA:
      const userAccessMuseumIds = action.payload?.appAccess?.museums?.map(({ id }) => id);
      return {
        ...state,
        userData: action.payload,
        userName: action.payload.email?.split('@')[0],
        userAccessMuseumIds: userAccessMuseumIds
      };
    case SET_USER_ACCESS_MUSEUM_IDS:
      return {
        ...state,
        userAccessMuseumIds: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const AuthsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authsReducer, { ...initialState }, undefined);

  return (
    <AuthsStateContext.Provider value={state}>
      <AuthsDispatchContext.Provider value={dispatch}>
        {children}
      </AuthsDispatchContext.Provider>
    </AuthsStateContext.Provider>
  );
};

export const useAuthsState = () => {
  const context = React.useContext(AuthsStateContext);
  if (undefined === context) {
    throw new Error('useAuthsState must be used within a AuthsProvider');
  }
  return context;
};

export const useAuthsDispatch = () => {
  const context = React.useContext(AuthsDispatchContext);
  if (undefined === context) {
    throw new Error('useAuthsDispatch must be used within a AuthsProvider');
  }
  return context;
};
