import {useMatch} from "react-router-dom";

export const QrScannerViewRoute = ({children}) => {
    const match = useMatch("/")

    if(match) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return null
    }
}