import CssBaseline from '@mui/material/CssBaseline';
import nb from 'date-fns/locale/nb';
import React from 'react';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import {BrowserRouter as Router} from 'react-router-dom';
import {HeaderProvider, WithHeader} from '@ekultur/header-microfrontend';
import {MenuTranslationProvider} from './menu/menuTranslationContext';
import {MuseumSelectorProvider} from './museum-selector/museumSelectorContext';
import {Routes} from './Routes';
import {Theme} from './app/Theme';
import {ViewportProvider} from './viewport/viewportContext';
import {Menu} from './menu/Menu';
import {SnackbarProvider} from 'material-ui-snackbar-provider';
import CustomSnackbar from './components/CustomSnackbar';
import {AppProvider} from "./app/appContext";
import {Box} from "@mui/material";
import {Layout} from "./app/Layout";

function App() {
    registerLocale('nb', nb);
    setDefaultLocale('nb');

    if (!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null;
    }
    return (
        <MuseumSelectorProvider>
            <Theme>
                <Router>
                    <HeaderProvider>
                        <AppProvider>
                            <WithHeader>
                                <CssBaseline/>
                                <ViewportProvider>
                                    <MenuTranslationProvider>
                                        <Box
                                            sx={{
                                                display: "flex"
                                            }}
                                        >
                                            <Menu/>
                                            <Layout>
                                                <SnackbarProvider SnackbarComponent={CustomSnackbar}>
                                                    <Routes/>
                                                </SnackbarProvider>
                                            </Layout>
                                        </Box>
                                    </MenuTranslationProvider>
                                </ViewportProvider>
                            </WithHeader>
                        </AppProvider>
                    </HeaderProvider>
                </Router>
            </Theme>
        </MuseumSelectorProvider>
    )
}

export default App;
