import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {AuthsProvider} from "../auths/authsContext";
import {CssBaseline} from "@mui/material";
import {AppHeader} from "../header/AppHeader";

export const RENDERED = 'rendered';
export const MENU_OPENED = 'menuOpened';
export const MENU_CLOSED = 'menuClosed';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
    museums: [],
    apps: [],
    menuOpen: false,
    rendered: false,
    refreshMuseums: 0,
    refreshApplications: 0
}

const appReducer = (state, action) => {
    console.debug(action);
    switch (action.type) {
        case RENDERED:
            return {
                ...state,
                rendered: true
            }
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true
            }
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(appReducer, {...initialState}, undefined);
    const {t} = useTranslation('bpn.scanner', {useSuspense: false});

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppTranslationContext.Provider value={t}>
                    <AuthsProvider>
                        <CssBaseline/>
                        <AppHeader/>
                        {children}
                    </AuthsProvider>
                </AppTranslationContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used within an AppProvider`);
    } else {
        return context;
    }
}

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
}

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider")
    } else {
        return context;
    }
}