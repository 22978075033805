import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressIndicator } from '../ProgressIndicator';

const TicketValidationTranslationContext = createContext(undefined);

export const TicketValidationTranslationProvider = ({ children }) => {
  const { t, ready } = useTranslation('bpn.ticketValidation', { useSuspense: false });

  if (ready) {
    return (
      <TicketValidationTranslationContext.Provider value={t}>{children}</TicketValidationTranslationContext.Provider>
    );
  } else {
    return <ProgressIndicator />;
  }
};

export const useTicketValidationTranslation = () => useContext(TicketValidationTranslationContext);
