import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import {useMuseumSelectorState} from '../museum-selector/museumSelectorContext';
import {useAuthsState} from '../auths/authsContext';
import useCustomSnackbar from '../components/useCustomSnackbar';
import Typography from '@mui/material/Typography';
import {useTicketValidationTranslation} from './TicketValidationTranslationContext';
import ScanResult from '../components/ScanResult';
import QrReader from 'react-web-qr-reader';
import {playConfirmation} from '../helpers/ValidationHelpers';
import VideocamIcon from '@mui/icons-material/Videocam';
import {Button, Stack, TextField} from '@mui/material';
import TicketValidationHistoryList from '../components/TicketValidationHistoryList/TicketValidationHistoryList';

export const ValidationAttemptStatus = {
    VALIDATED: 'validated',
    ALREADY_USED: 'already_used',
    EVENT_NOT_STARTED: 'event_not_started',
    EVENT_PASSED: 'event_not_started',
    IN_TIMEFRAME: 'event_not_started',
    CREDITED: 'credited',
};

const ManualInputContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
});

const OnOffCameraButtonContainer = styled('div')({
    width: '100%',
    textAlign: 'center',
});

const OnOffButton = styled(Button)(({theme}) => ({
    height: '70%',
    width: '90%',
    verticalAlign: 'bottom',
    borderRadius: '0%',
    [`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
        width: '50%',
    },
}));

const ValidateButton = styled(Button)(({theme}) => ({
    marginLeft: theme.spacing(2),
    height: '70%',
    verticalAlign: 'bottom',
}));

const DisabledCameraContainer = styled('div')(({theme}) => ({
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(4),
}));

const StyledQrReader = styled(QrReader)(({theme}) => ({
    width: '90%',
    boxSizing: 'border-box',
    objectFit: 'fill',
    [`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
        width: '50%',
    },
}));

const StyledVideoCamIcon = styled(VideocamIcon)(({theme}) => ({
    fontSize: '10rem',
    margin: theme.spacing(2),
}));

const ScanResultContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const QrScannerView = () => {
    const [scanResult, setScanResult] = useState('');
    const [mainTicketScanResult, setMainTicketScanResult] = useState('');
    const [ticketValidationHistory, setTicketValidationHistory] = useState([]);
    const [isCameraOn, setIsCameraOn] = useState(false);
    const [lastScannedTicketCode, setLastScannedTicketCode] = useState('');
    const [useFrontFacingCamera, setUseFrontFacingCamera] = useState(false);
    const [failedTimes, setFailedTimes] = useState(0);
    const {selectedMuseum} = useMuseumSelectorState();
    const t = useTicketValidationTranslation();
    const {userData} = useAuthsState();
    const snackbar = useCustomSnackbar();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    useEffect(() => {
        setIsCameraOn(false);
    }, [selectedMuseum]);

    const handleSingleTicketScanned = scanResult => {
        setMainTicketScanResult('');
        setScanResult('');
        setTicketValidationHistory([]);
        const validationResult = scanResult[0];
        if (validationResult.status === 'validated') {
            playConfirmation(true);
            setScanResult(validationResult);
            showSnackBarMessage(validationResult.status);
        } else if (validationResult.status !== 'validated') {
            playConfirmation(false);
            setScanResult(validationResult);
            showSnackBarMessage(validationResult.status);
        }

        if (validationResult.ticketId) {
            fetch(
                `${window._env_.REACT_APP_BPN_ADMIN_API}tickets/${validationResult.ticketId}`
            )
                .then(response => response.json())
                .then(json => {
                    const ticketHistory = json.ticket.validationAttempts;
                    setTicketValidationHistory(ticketHistory);
                })
                .catch(error => console.error(error))
        }
    };

    const allTicketsValid = mainTicketScanResult => {
        let isAllValid = true;
        for (const validationResult in mainTicketScanResult) {
            if (validationResult.status !== ValidationAttemptStatus.VALIDATED) {
                isAllValid = false;
            }
        }

        return isAllValid;
    };

    const handleMainTicketScanned = scanResult => {
        setMainTicketScanResult('');
        setScanResult('');
        setMainTicketScanResult(scanResult);
        setTicketValidationHistory([]);
        if (allTicketsValid(scanResult)) {
            playConfirmation(true);
        } else {
            playConfirmation(false);
        }
    };

    const runValidation = ticketCode => {
        fetch(
            `${window._env_.REACT_APP_BPN_ADMIN_API}tickets/${ticketCode}/validate`,
            {
                method: "PUT",
                body: JSON.stringify({
                    userId: userData.uniqueId,
                    userName: userData.name,
                    museumId: selectedMuseum.id,
                    museumName: selectedMuseum.name,
                })
            }
        )
            .then(response => response.json())
            .then(json => {
                const scanResult = json?.validationAttempts;
                if (scanResult?.length < 2) {
                    handleSingleTicketScanned(scanResult);
                } else if (scanResult?.length > 1) {
                    handleMainTicketScanned(scanResult);
                } else {
                    snackbar.showError(scanResult.message);
                }
            })
            .catch(() => {
                playConfirmation(false);
                setScanResult('');
                snackbar.showError(t('invalidTicket'));
            });
    };

    const handleScanWebCam = ticketCode => {
        if (ticketCode.data) {
            if (ticketCode.data !== lastScannedTicketCode) {
                setLastScannedTicketCode(ticketCode.data);
                runValidation(ticketCode.data);
            }
        }
    };

    const handleManualEnteredValidation = e => {
        e.preventDefault();
        setScanResult('');
        runValidation(lastScannedTicketCode);
    };

    const showSnackBarMessage = status => {
        switch (status) {
            case ValidationAttemptStatus.VALIDATED:
                snackbar.showSuccess(t('validTicket'));
                break;
            case ValidationAttemptStatus.EVENT_NOT_STARTED:
                snackbar.showWarning(t('eventNotStarted'));
                break;
            case ValidationAttemptStatus.CREDITED:
                snackbar.showWarning(t('credited'));
                break;
            case ValidationAttemptStatus.EVENT_PASSED:
                snackbar.showWarning(t('eventPassed'));
                break;
            case ValidationAttemptStatus.ALREADY_USED:
                snackbar.showWarning(t('alreadyUsed'));
                break;
            default:
                snackbar.showError(t('invalidTicket'));
                break;
        }
    };

    const onError = () => {
        setUseFrontFacingCamera(true);
        setFailedTimes(failedTimes + 1);
        if (failedTimes > 1) {
            snackbar.showError(t('scannerFail'));
        }
    };

    if (!selectedMuseum || selectedMuseum.name === '') {
        return (
            <Typography variant={'h4'} align={'center'}>
                {t('noMuseumSelected')}
            </Typography>
        );
    }

    return (
        <Stack
            direction={"column"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            {isCameraOn ? (
                <StyledQrReader
                    delay={500}
                    facingMode={useFrontFacingCamera ? 'user' : 'environment'}
                    onScan={handleScanWebCam}
                    onError={onError}
                    showViewFinder={true}
                />
            ) : (
                <DisabledCameraContainer>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={() => {
                            setIsCameraOn(true);
                        }}>
                        <StyledVideoCamIcon/>
                    </Button>
                </DisabledCameraContainer>
            )}
            <OnOffCameraButtonContainer>
                <OnOffButton
                    variant={'contained'}
                    onClick={() => {
                        setIsCameraOn(!isCameraOn);
                    }}
                    color={'primary'}>
                    {isCameraOn ? 'Slå av kamera' : 'Slå på kamera'}
                </OnOffButton>
            </OnOffCameraButtonContainer>

            <ManualInputContainer>
                <form onSubmit={handleManualEnteredValidation}>
                    <TextField
                        required={true}
                        value={lastScannedTicketCode}
                        label={t('ticketCode')}
                        variant={'standard'}
                        onChange={event => setLastScannedTicketCode(event.target.value.toUpperCase())}
                    />
                    <ValidateButton variant={'contained'} type="submit" color={'primary'}>
                        {t('validateButton')}
                    </ValidateButton>
                </form>
            </ManualInputContainer>
            <ScanResultContainer>
                {!mainTicketScanResult ? (
                    <ScanResult validationAttempt={scanResult}/>
                ) : (
                    mainTicketScanResult.map(scanResult => (
                        <ScanResult key={scanResult.ticketId} validationAttempt={scanResult}/>
                    ))
                )}
                {ticketValidationHistory.length > 0 ? (
                    <TicketValidationHistoryList items={ticketValidationHistory}/>
                ) : undefined}
            </ScanResultContainer>
        </Stack>
    );
};
export default QrScannerView;
