// noinspection NpmUsedModulesInstalled
import {refreshToken} from '@ekultur/authentication';
import {makeStyles} from '@mui/styles';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuthsDispatch, USER_LOGGED_IN} from './authsContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export const WithAuthentication = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAuthsDispatch();

  useEffect(() => {
    if (!location.pathname.startsWith('/oauth2/callback')) {
      refreshToken({
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        onSuccess: () => dispatch({ type: USER_LOGGED_IN }),
        onError: () => console.log('Could not refresh token. User must log in!'),
      });
    }
  }, [dispatch, location.pathname]);

  return <div className={classes.root}>{children}</div>;
};
