import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMenuTranslation} from './menuTranslationContext';
import MenuItem from './MenuItem';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {useAuthsState} from '../auths/authsContext';
import MuseumSelector from '../components/MuseumSelector';
import {useMuseumSelectorState} from '../museum-selector/museumSelectorContext';
import {MENU_CLOSED, MENU_OPENED, useAppDispatch, useAppState} from "../app/appContext";
import {Box, List, styled, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.drawerWidth,
            flexShrink: 0,
            zIndex: 1,
        },
    },
    drawerPaper: {
        width: theme.drawerWidth,
        overflow: 'hidden',
    },
    buttonNew: {},
}));

const Nav = styled("nav")(({theme}) => ({
    zIndex: theme.zIndex.appBar - 1,
}))

const iconStyle = {color: 'gray', fontSize: 20};

export const Menu = () => {
    const classes = useStyles();
    const t = useMenuTranslation();
    const {menuOpen} = useAppState();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {selectedMuseum} = useMuseumSelectorState();
    const menuItems = getMenuItems(t);
    const {userIsAuthenticated} = useAuthsState();
    const [expanded, setExpanded] = useState(null);
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => setExpanded('/'), []);

    useEffect(
        () => {
            if (isDesktop) {
                dispatch({type: MENU_OPENED})
            } else {
                dispatch({type: MENU_CLOSED})
            }
        }, [isDesktop]
    )

    function getMenuItems(t) {
        return [
            {
                label: t('ticketScanner'),
                icon: <CameraAltIcon style={iconStyle}/>,
                url: "/",
                disabled: !selectedMuseum || selectedMuseum.name === '',
            },
            // Separate view used for only manual validation
            // {
            //   label: t('manualValidation'),
            //   icon: <KeyboardIcon style={iconStyle} />,
            //   url: MANUAL_VALIDATION_VIEW,
            //   disabled: !selectedMuseum,
            // },
        ];
    }

    const handleExpandedChange = (key, url) => (event, newExpanded) => {
        onMenuClick(url);
        setExpanded(newExpanded ? key : false);
    };

    const onMenuClick = url => {
        dispatch({type: MENU_CLOSED});
        navigate(url);
    };

    const drawer = (
        <>
            <MuseumSelector/>
            <List>
                {menuItems.map(({label, icon, url, subItems, disabled = false}, index) => (
                    <MenuItem
                        key={url}
                        itemKey={url}
                        label={label}
                        disabled={disabled}
                        onMenuClick={onMenuClick}
                        url={url}
                        icon={icon}
                        expanded={expanded}
                        subItems={subItems}
                        handleExpandedChange={handleExpandedChange}
                    />
                ))}
            </List>
        </>
    );

    if (userIsAuthenticated) {
        return (
            <Nav>
                <Box
                    sx={{
                        width: {
                            md: theme => menuOpen ? theme.drawerWidth : 0
                        },
                        flexShrink: {
                            md: 0
                        },
                    }}
                >
                    <Hidden smUp implementation="js">
                      <Drawer
                        variant="temporary"
                        open={menuOpen}
                        onClose={() => dispatch({type: MENU_CLOSED})}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{ keepMounted: true }}
                      >
                        {drawer}
                      </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="js">
                        <Drawer
                            open={menuOpen}
                            variant="persistent"
                            PaperProps={{
                                elevation: 4,
                            }}
                            sx={{
                                overflow: 'hidden',
                                '& .MuiDrawer-paper': {
                                    width: theme => theme.drawerWidth,
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    overflow: 'auto',
                                    height: "100%"
                                }}
                            >
                                <Toolbar/>
                                {drawer}
                            </Box>
                        </Drawer>
                    </Hidden>
                </Box>
            </Nav>
        )
    } else {
        return null
    }
};
