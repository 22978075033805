import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Item from './Item';
import { useTicketValidationTranslation } from '../../views/TicketValidationTranslationContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '50ch',
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  header: {
    padding: '10px',
    // margin: '10px',
  },
}));

const TicketValidationHistoryList = ({ items }) => {
  const classes = useStyles();
  const t = useTicketValidationTranslation();

  const compare = (a, b) => {
    const timeA = a.attemptAt;
    const timeB = b.attemptAt;

    let comparison = 0;
    if (timeA < timeB) {
      comparison = 1;
    } else if (timeA > timeB) {
      comparison = -1;
    }
    return comparison;
  };

  const itemsDescending = items.sort(compare);

  return (
    <div>
      <h2 className={classes.header}>{t('validationHistory')}</h2>
      {itemsDescending.map((item, index) => (
        <Item {...item} key={index} />
      ))}
    </div>
  );
};

export default TicketValidationHistoryList;
