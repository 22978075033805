import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Button, Alert } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const StyledAlert = withStyles(theme => ({
  root: {
    width: '50vw',
    height: '20vh',
  },
  message: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  icon: {
    position: 'relative',
    fontSize: '2.5rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [`${theme.breakpoints.up('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
}))(Alert);

export default function CustomSnackbar({ message, action, ButtonProps, SnackbarProps, customParameters }) {
  return (
    <Snackbar autoHideDuration={2000} {...SnackbarProps} >
      <StyledAlert
        severity={customParameters?.type}
        elevation={8}
        action={
          action != null && (
            <Button color="inherit" size="small" {...ButtonProps}>
              {action}
            </Button>
          )
        }>
        {message}
      </StyledAlert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  message: PropTypes.string,
  action: PropTypes.string,
  ButtonProps: PropTypes.object,
  SnackbarProps: PropTypes.object,
  customParameters: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  }),
};
