import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  'nb-NO': {
    'bpn.ticketValidation': {
      already_used: 'Allerede validert',
      invalidTicket: 'Ikke godkjent',
      ticketAlreadyUsed: 'Billett allerede brukt',
      scanResultHeading: 'Billett id ',
      message: 'Beskjed',
      museum: 'Museum',
      noMuseumSelected: 'Velg museum fra sidemenyen før du validerer billetter',
      notAllValid: 'Ikke alle koblede billetter ble godkjent',
      scannerFail: 'Qr-skanner feilet',
      status: 'Status',
      subTicketCount: 'Antall koblede billetter',
      ticketCode: 'Billettkode',
      ticketId: 'Billett ID: ',
      type: 'Type',
      validateButton: 'Valider',
      validated: 'Validert',
      validationAttemptMessage: 'Beskjed: ',
      validationHistory: 'Valideringshistorikk',
      validationType: 'Valideringstype',
      validSubTicketCount: 'Antall godkjente koblede billetter',
      validTicket: 'Godkjent',
      eventNotStarted: 'Arrangementet har ikke startet',
      credited: 'Den tilhørende orderen er kreditert',
      eventPassed: 'Arrangementet er ferdig',
      alreadyUsed: 'Billetten er allerede brukt',
      viewHeader: 'Manuell bilettvalidering',
    },
    'bpn.Menu': {
      ticketScanner: 'Billettskanner',
      manualValidation: 'Manuell validering',
    },
  },
};

i18n
  .use(Locize)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'nb-NO',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true,
    backend: {
      projectId: '6c69e1ba-4e7b-47db-bc46-1ca0bf4dcbe4',
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      referenceLng: 'nb-NO',
      fallbacklng: 'nb-NO',
      allowedAddOrUpdateHosts: ['localhost'],
    },
  })
  .then(() => console.info('i18n init success'));

export default i18n;
