import React, { useEffect, useState } from 'react';
import { styled, TextField, Autocomplete } from '@mui/material';
import { getMuseum } from '../endpoints/museums';
import {
  SET_SELECTED_MUSEUM,
  useMuseumSelectorDispatch,
  useMuseumSelectorState,
} from '../museum-selector/museumSelectorContext';
import { getUserData } from '../endpoints/authorization';

const MuseumsSelectorAutocomplete = styled(Autocomplete)({
  marginTop: '15px',
  marginLeft: '8px',
  marginRight: '8px',
});

export default function MuseumSelector() {
  const [availableMuseums, setAvailableMuseums] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useMuseumSelectorDispatch();
  const { selectedMuseum } = useMuseumSelectorState();
  const [value, setValue] = useState(selectedMuseum || { name: '', category: '' });

  useEffect(() => {
    getUserData().then(data => {
      if (data.appAccess) {
        {
          const fetchedMuseums = [];
          let isAdmin = false;
          data.appAccess.apps.map(app => {
            if (app.id === window._env_.REACT_APP_ID && app.role?.name === 'Admin') {
              isAdmin = true;
            }
          });

          const requests = data.appAccess.museums.map(museum => {
            if (isAdmin) {
              return getMuseum(museum.id).then(fullMuseum => {
                fetchedMuseums.push(fullMuseum);
              });
            } else if (museum.applications.filter(app => app.id === window._env_.REACT_APP_ID).length > 0) {
              return getMuseum(museum.id).then(fullMuseum => {
                fetchedMuseums.push(fullMuseum);
              });
            }
          });

          return Promise.all(requests).then(() => {
            if (fetchedMuseums.length === 1) {
              dispatch({
                type: SET_SELECTED_MUSEUM,
                payload: fetchedMuseums[0],
              });
            }

            const options = fetchedMuseums.map(museum => {
              const firstLetter = museum.name[0].toUpperCase();
              return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...museum,
              };
            });

            setAvailableMuseums(
              options.sort((a, b) => -b.type.localeCompare(a.type) || -b.firstLetter.localeCompare(a.firstLetter))
            );
            setLoading(false);
          });
        }
      }
    });
  }, [dispatch]);

  return (
    <>
      {!loading && (
        <MuseumsSelectorAutocomplete
          options={availableMuseums}
          value={value}
          getOptionSelected={(option, value) => option.name === value.name || value.type === 'root'}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(newValue);
              dispatch({
                type: SET_SELECTED_MUSEUM,
                payload: newValue,
              });
            } else {
              setValue(null);
              dispatch({
                type: SET_SELECTED_MUSEUM,
                payload: { name: '', category: '' },
              });
            }
          }}
          groupBy={option => (option.type === 'umbrella' ? 'Selskap' : 'Museum')}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label={'Museum'} variant="outlined" />}
        />
      )}
    </>
  );
}
