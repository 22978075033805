export const playConfirmation = valid => {
  const audioContext = new AudioContext();
  const oscillator = audioContext.createOscillator();
  const gain = audioContext.createGain();
  oscillator.connect(gain);
  gain.connect(audioContext.destination);
  oscillator.frequency.value = valid ? 3000 : 300;
  oscillator.type = 'sine';
  gain.gain.value = 10;
  oscillator.start(audioContext.currentTime);
  const stopTime = valid ? 200 : 500;
  oscillator.stop(audioContext.currentTime + stopTime * 0.001);
};
