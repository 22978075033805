import React, { createContext, useContext, useEffect, useReducer } from 'react';

export const SET_SELECTED_MUSEUM = 'museumSelector/setSelectedMuseum';
const LOCAL_STORAGE_KEY = 'bpn.museumSelectorContext';

const MuseumSelectorStateContext = createContext(undefined);
const MuseumSelectorDispatchContext = createContext(undefined);

const localState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
const initialState = {
  selectedMuseum: null,
};

const museumSelectorReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_MUSEUM:
      return {
        ...state,
        selectedMuseum: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const MuseumSelectorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(museumSelectorReducer, { ...initialState, ...localState });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  return (
    <MuseumSelectorStateContext.Provider value={state}>
      <MuseumSelectorDispatchContext.Provider value={dispatch}>{children}</MuseumSelectorDispatchContext.Provider>
    </MuseumSelectorStateContext.Provider>
  );
};

export const useMuseumSelectorState = () => useContext(MuseumSelectorStateContext);
export const useMuseumSelectorDispatch = () => useContext(MuseumSelectorDispatchContext);
